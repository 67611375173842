<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          src="@/assets/images/logo/logo-light.svg"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('yourLeadingPlatform') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('createAnAccountForTrading') }}!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- notifications -->
              <b-form-group
                :label="$t('email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('agreeTo') }}
                  <b-link>{{ $t('privacyPolicyTerms') }}</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || !status"
              >
                {{ $t('signUp') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('alreadyHaveAnAccount') }}?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('signInInstead') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--              variant="google"-->
          <!--              :disabled="true"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="MailIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="linkedin"-->
          <!--              :disabled="true"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="LinkedinIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="facebook"-->
          <!--              :disabled="true"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="FacebookIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async register() {
      this.$refs.registerForm.validate().then(async success => {
        if (success && this.status) {
          try {
            const newForm = new FormData();
            newForm.set('email', this.userEmail);
            const { data } = await this.$http.post('/register', newForm);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Account activation link sent to ${this.userEmail}! Please follow the link in the email to proceed with your registration.`,
                icon: 'EditIcon',
                variant: 'success',
              },
            });

            await this.handleLoginResponse(data);
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: e,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            });
          }
        }
      });
    },
    async handleLoginResponse(data) {
      if (data.status) {
        localStorage.setItem('Authorization', data.data.token);
        this.$http.defaults.headers.Authorization = `Bearer ${data.data.token}`;
        await this.$router.push('/');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: data.error,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss" scoped>
.brand-logo {
  &::v-deep img {
    max-width: 80px;
  }
}
</style>
